import React from "react";
import styled from "styled-components";

export default function Map() {
  return (
    <Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.0770601825725!2d11.487858315565672!3d45.70949087910454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4778c9f6909bcd3f%3A0x8e0e5dfb81e59be9!2sStudio%20Dalla%20Stella!5e0!3m2!1sen!2sit!4v1568702450508!5m2!1sen!2sit"
        frameBorder="0"
        allowFullScreen
      />
    </Container>
  );
}

const Container = styled.div`
  iframe {
    width: 100%;
    height: 460px;
    border: 0;
    display: block;
  }
`;
