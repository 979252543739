import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Page from "components/Page";
import Wrapper from "components/Wrapper";
import ContactForm from "components/ContactForm";
import NewsletterForm from "components/Newsletter";
import { PageLocale } from "types";
import Map from "components/Map";
import {
  Separator,
  Hero,
  Section,
  Flexbox,
  HeroTitle,
  HeroSubtitle,
  SectionHeading,
  SectionInfo,
  SectionDescription,
  HeroMessageContainer,
  HeroImg,
  Email,
  HeroImageContainer,
  Phone
} from "components/UI";
interface Props {
  data: {
    page: {
      fields: {
        name: string;
        locales: {
          language: string;
          path: string;
          title: string;
          seo: {
            description: string;
            image: string;
          };
          hero: {
            title: string;
            subtitle: string;
            image: string;
          };
          contacts: {
            title: string;
            subtitle: string;
            email: string;
          };
        }[];
      };
    };
  };
  pageContext: {
    name: string;
    locale: string;
  };
}

const ContactsPage = ({ data, pageContext }: Props) => {
  const currentPage = data.page.fields.locales.find(
    locale => locale.language === pageContext.locale
  )!;

  return (
    <Page
      title={currentPage.title}
      description={currentPage.seo.description}
      localeCode={pageContext.locale}
      image={currentPage.seo.image}
      pageLocales={data.page.fields.locales.map(
        (locale): PageLocale => ({
          code: locale.language,
          url: locale.path
        })
      )}
    >
      <Hero secondary>
        <HeroImageContainer>
          <HeroImg src={currentPage.hero.image} />
        </HeroImageContainer>
        <Wrapper>
          <HeroMessageContainer>
            <HeroTitle data-aos="fade-right">
              {currentPage.hero.title}
            </HeroTitle>
            <Separator light />
            <HeroSubtitle data-aos="fade-right" data-aos-delay="300">
              {currentPage.hero.subtitle}
            </HeroSubtitle>
          </HeroMessageContainer>
        </Wrapper>
      </Hero>
      <ContactsSection data-aos="fade-up">
        <Wrapper>
          <Flexbox>
            <SectionHeading>
              <SectionInfo>{currentPage.contacts.title}</SectionInfo>
              <Separator />
              <Phone href="tel:0445-360277">0445 360277</Phone>
              <Email
                href={`mailto:${currentPage.contacts.email}`}
                target="_top"
              >
                {currentPage.contacts.email}
              </Email>
              <SectionDescription>
                {currentPage.contacts.subtitle}
              </SectionDescription>
            </SectionHeading>
            <ContactForm pageName="contacts" />
          </Flexbox>
        </Wrapper>
      </ContactsSection>
      <NewsletterForm listId="98e7e97d2a" checkbox />
      <Map />
    </Page>
  );
};

export const query = graphql`
  query ContactsPageQuery($name: String!) {
    page: pagesJson(fields: { name: { eq: $name } }) {
      fields {
        name
        locales {
          language
          path
          title
          seo {
            description
            image
          }
          hero {
            title
            subtitle
            image
          }
          contacts {
            title
            subtitle
            email
          }
        }
      }
    }
  }
`;

const ContactsSection = styled(Section)`
  ${Flexbox} {
    flex-direction: column;
  }
  ${SectionHeading} {
    text-align: center;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
  }
  ${Email} {
    font-size: 14px;

    padding-top: 10px;
    margin-bottom: 40px;
  }
`;

export default ContactsPage;
